<template>
  <div style="background: #F6F6F6; height: 100%;">
    <div 
      style="height: 44px; 
      display: flex; justify-content: space-between;
      padding: 0 2.6%;
      background: #fff;
      position: fixed;
      width: 100%;
      z-index: 100;
      box-sizing: border-box;
      align-items: center;"
    >
      <van-icon
        name="arrow-left" 
        size="20" 
        style="font-weight: 600;"
        class="Ge-arrow-left"
        @click="$router.go(-1)"
      />
      <span style="font-size: 17px; font-weight: 600;">商品详情</span>
      <div style="display: felx; align-items: center;">
        <van-icon name="chat-o" size="20" style="font-weight: 600; margin-right: 12px;" @click="contact(im)"/>
        <i 
          style="font-size: 20px; font-weight: 600; font-size: 20px;" class="iconfont icon-fenxiang"
          @click="shareBox = true"
        ></i>
      </div>
    </div>

    <div style="padding-top: 44px;" class="swipe-img">
      <van-swipe :autoplay="3000" indicator-color="white" width="100%">
        <van-swipe-item
          v-for=" (item, index) in detail.goodsImages"
          :key="index"
        >
          <img style="width: 100%;" :src="item" alt="">
        </van-swipe-item>
      </van-swipe>
    </div>

    <div style="padding: 7px 4.2% 15px; background: #fff; margin-bottom: 10px;">
      <div style="display: flex; justify-content: space-between;">
        <p style="font-size: 17px; font-weight: 600; color: #333;">{{ detail.goodsName }}</p>
        <div
          @click="collectShopping(collectShoppingNum)"
          style=" height: 38px;
          display: flex; flex-direction: column;
          justify-content: center; 
          align-items: center;">
          <van-icon name="star-o" size="20" v-if="collectShoppingNum%2==0" />
          <van-icon name="star" size="20" v-if="collectShoppingNum%2==1" color="#FF3C00"/>
          <p style="font-size: 12px; width: 25px; transform: scale(.83);">收藏</p>
        </div>
      </div>
      <p style="font-size: 12px; color: #999; margin: 0px 0 5px;">月售{{ detail.goodsMouthSell }}份</p>
      <div style="display: flex;" v-if="detail.noSingle == 1 ? true : false">
        <div style="font-size: 12px; transform: scale(.8) translateX(-8px); border: 1px solid rgb(153, 153, 153); padding: 1px 10px; border-radius: 5px; color: rgb(153, 153, 153);">
          {{ detail.noSingle == 1 ? '单点不送' : '' }}
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div>
          <span style="color: #A80000; margin-right: 2.6%;">
            <span>￥</span>
            <span style="font-size: 24px;">{{ 
              detail.discountPrice ? (detail.discountUser == 0 ? detail.discountPrice: (flag == 0 ? detail.discountPrice : detail.price)) : detail.price 
            }}</span>
          </span>
          <span 
            v-if="detail.discountPrice ? detail.discountUser == 0 ? true : flag == 0 ? true : false : false"
            style="color: red; color: #999; text-decoration: line-through; margin-left: 5px;"
          >
          <span
            style="font-size: 12px; font-size: 12px;"
          >￥</span>{{ detail.price }}

          </span>
        </div>
        <div class="detailStepper">
          <div v-if="standardName.length ? false : true">
            <van-button
              v-if="detailShowHide ? false : true"
              color="linear-gradient(to right, #FF7200, #FF3C00)" 
              size="mini"
              @click="detail_add_cart_shop"
            >
              <van-icon name="plus" style="margin-right: 5px;" />
              <span>加入购物车</span>
            </van-button>

            <van-stepper
              v-if="detailShowHide ? true : false"
              v-model="detailStepper"
              disable-input
              min="0"
              button-size="19"
              input-width="19"
              @change="detailShopChange"
            />
          </div>

          <div style="display: flex; flex-direction: column; background: rgb(246, 245, 240);">
            <div
                v-if="detail.minBuy == 1 || detail.minBuy == null || detail.minBuy == '' ? false : true"
                style="foot-size: 12px; 
                display: flex; 
                justify-content: center; 
                align-items: center;
                color: rgb(140, 140, 140);
                transform: scale(.75);"
              >
                <span style="">{{ detail.minBuy }}份起购</span>
              </div>
            <van-button
              v-if="standardName.length ? true : false"
              size="mini" 
              color="#FF5200"
              @click="selectSpecification(goodsName, foodId, 
              goodsImages, discountPrice, 
              price,discountUser,stockCurrent)"
            >选规格</van-button>
          </div>
          
        </div>
      </div>
    </div>

    <div style="background: #fff; padding: 15px 4.2% 0;">
      <div style="display: flex; color: #999;">
        <div
          style="padding: 9px 0;
          font-weight: 600;
          border-bottom: 1px solid transparent; 
          margin-right: 11.7%;" 
          class="active"
        >详情</div>
        <div
          style="padding: 9px 0; 
          display: flex;
          font-weight: 600;
          border-bottom: 1px solid transparent; 
          display: flex;" 
        >
          <div>评价</div>
          <div style="font-size: 12px; transform: scale(.8); margin-top: 4px;">{{ detail.goodsTotal }}</div>
        </div>
      </div>
    </div>

    <div style="padding: 15px 4.2%; font-size: 12px; background: white; margin-bottom: 10px ;">
      <div class="shopping-detail">
        <div style="width: 18%; color: #999; margin-right: 2%;">套餐内容：</div>
        <div style="flex: 1; color: 333;">{{ detail.setMeal }}</div>
      </div>
      <div class="shopping-detail">
        <div style="width: 18%; color: #999; margin-right: 2%;">主料：</div>
        <div style="flex: 1; color: 333;">{{ detail.prime }}</div>
      </div>
      <div class="shopping-detail">
        <div style="width: 18%; color: #999; margin-right: 2%;">辅料：</div>
        <div style="flex: 1; color: 333;">{{ detail.accessory }}</div>
      </div>
      <div>
        <div class="shopping-detail" 
          v-for="(item, index) in standardName"
          :key="index"
        >
          <div style="width: 18%; color: #999; margin-right: 2%;">{{ item.name }}：</div>
          <div style="flex: 1; color: 333;">{{ item.standardContent }}</div>
        </div>
      </div>
    </div>

    <!-- 评价 -->
    <div style="padding: 0 4.2% 41px; background: white;">
      <div style="display: flex; justify-content: space-between; padding: 10px 0;">
        <div style="display: flex; align-items: center;">
          <span style="font-weight: bold;">评价</span>
          <div
            v-if="applauseRate"
            style="font-size: 12px; 
            transform: scale(.83);
            margin-top: 3px; color: #999999;"
          >（好评度{{ applauseRate }}%）</div>
        </div>
        <div style="color: #999999; font-size: 14px;">
          <span style="margin-right: 5px;">{{ evaluateTotal }}条评论</span>
          <van-icon name="arrow" />
        </div>
      </div>

      <div>
        <div>
          <div 
            style="display: flex; 
            justify-content: space-between; 
            border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
            padding: 9px 0 16px;"
            v-for="(item, index) in evaluateContent"
            :key="index"
          >
            <img
              style="width: 55px; height: 55px;"
              :src="item.appraiseHeader" alt="">
            <div style="width: 78%;">
              <div style="display: flex; justify-content: space-between;">
                <span style="font-size: 14px; font-weight: bold;">{{ item.appraiseName }}</span>
                <span  style="font-size: 12px; color: #999;">{{ item.appraiseTime }}</span>
              </div>
              <p style="color: #999; font-size: 12px;">{{ item.appraiseContent }}</p>
            </div>
          </div>
        </div>

        <div style="position: ; 
          bottom: 0; left: 0;
          height: 17px;
          padding: 10px 0;
          text-align: center;
          width: 100%;
          font-size: 12px; color: #333;"
        >查看全部{{ evaluateTotal }}条评论></div>
      </div>
    </div>

    <div class="bottom-GoodsAction">
      <van-goods-action>
        <van-goods-action-icon icon="cart-o" text="购物车" @click="clickCart" />
        <div 
          style="border-radius: 20px 0 0 20px;
          width: 60%;
          position: relative;
          box-sizing: border-box;        
          background: #313131; 
          height: 40px;"
        >
          <div
            style="display: flex;
            top: 3.5px;
            left: 8.2%;
            position: absolute;
            align-items: center;"
          >
            <div style="color: #fff;">
              <span style="font-size: 12px;">￥</span>
              <span style="font-size: 14px;">{{ shopPrice[1] }}</span>
            </div>
            <div style="font-size: 12px; transform: scale(.83); margin-top: 3px; color: #999;">￥{{ shopPrice[0] }}</div>
          </div>
          <div
            style="font-size: 12px;
            position: absolute;
            bottom: 0px;
            left: 15px;
            color: #fff;"
          >
            <span>预估配送费￥{{ transportUserPrice }}</span>
            <span
              style="transform: scale(.83); margin-left: 5px; color: #999;"
              v-if="transportStartPriceResult > 0 ? true : false"
            >差￥{{ transportStartPriceResult }}起送</span>
            <span
              style="transform: scale(.83); margin-left: 5px; color: #999;"
            >
              {{
                transportStartPriceResult > 0 ? '' : noDistribution == true ? '单点不配送' : ''
              }}
            </span>
          </div>
        </div>
        <van-goods-action-button
          type="danger"
          :disabled="disabled"
          text="去结算"
          @click="goSettle"
        />
      </van-goods-action>
    </div>

    <!-- 购物车面板 -->
    <div>
      <van-action-sheet v-model="show">
        <div 
          style="padding: 53px 4% 51px; position: relative;"
          class="shop-box"
        >
          <div
            style="position: absolute;
              top: 0;
              left: 3.9%;
              right: 4%;
              width: 92%;
              padding: 15px 0;
              background: #fff;"
            >
            <p 
              style="font-size: 15px;
              color: #333; text-align: center;"
            >购物车</p>
            <div
              style="color: #999; 
              position: absolute; 
              right: 0;
              display: flex;
              align-items: center;
              top: 15px;"
              @click="emptyShopCar"
            >
              <van-icon name="delete-o" size="20"/>
              <span 
                style="font-size: 12px; margin-left: 3px; padding-top: 2px;"
              >清空购物车</span>
            </div>
          </div>

          <div>
            <!-- 购物车商品 -->
            <div class="shopCartShop">
              <div
                style="display: flex; 
                  justify-content: space-between; 
                  padding: 10px 0;"
                  v-for="(item, index) in shopCar"
                  :key="item.nanoid"
              >
                <img style="width: 93px; height: 93px;" :src="item.goodsImages" alt="">
                <div style=" width: 69%;">
                  <p style="font-size: 13px; color: #333;">{{ item.goodsName }}</p>
                  <p style="font-size: 12px; color: #999; margin-top: 5px;">
                    <span
                      style="margin-right: 5px;"
                      v-for="(item2, index2) in item.specificationShow"
                      :key="index2"
                    >{{ item2 }}</span>
                  </p>
                  <div 
                    style="display: flex; 
                      justify-content: space-between;
                      margin-top: 10px;
                      align-items: center;">
                    <div style="display: flex;">
                      <div style="display: flex; margin-right: 5px; color: #A80000;">
                        <div style="font-size: 12px; transform: scale(.8); margin-top: 3px;">￥</div><span>{{ (item.price * item.quantity).toFixed(2) }}</span>
                      </div>
                      <div style="display: flex; text-decoration: line-through; color: #999;">
                        <div style="font-size: 12px; transform: scale(.8); margin-top: 3px;">￥{{ (item.originalPrice * item.quantity).toFixed(2) }}</div>
                      </div>
                    </div>
                    <div>
                      <van-stepper 
                        v-model="item.quantity" 
                        disable-input
                        min="0"
                        button-size="19"
                        input-width="19"
                        @change="shopCarChange(index,$event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>

    <!-- 选规格弹框 -->
    <div class="shopSpecification">
      <van-dialog 
        v-model="dialogShow"
        width="89%"
        :close-on-click-overlay="true"
        :showConfirmButton="false"
      >
        <div style="padding: 20px 5.3%; 0">
          <p style="text-align: center; color: #333;">{{ goodsName }}</p>
          <div>
            <div 
              v-for="(item, index) in standard" :key="index"
              style="margin-bottom: 3px;"
            >
              <p style="font-size: 12px; color: #333; margin: 6px 0 3px;">{{ item.name }}</p>
              <div 
                style="display: flex;"
              >
                <div
                  v-for="(item2, index2) in item.contenList"
                  :key="index2"
                  :class="{copies: copies[index] == index2}"
                  @click="cppiesTabs(index, index2, item2.standardName, item2.addPrice, item2.standardId, item2.id)"
                  style="width: 85px; 
                  height: 30px; font-size: 12px;
                  border: 1px solid #999;
                  display: flex;
                  margin: 0 4% 0 0;
                  align-items: center;
                  justify-content: center;
                  border-radius: 5px;"
                >{{ item2.standardName }}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="font-size: 12px; #999;
            display: flex;
            padding-left: 5.3%;
            margin-top: 20px;
            box-sizing: border-box;
            align-items: center;
            background: #F6F6F6; 
            height: 33px;"
        >
          <span style="color: #999;">
            <span>已选规格：</span>
            <span style="color: #333;" v-for="(item, index) in specificationShow" :key="index">
              {{ item }} {{ specificationShow.length == index + 1 ? '' : '、' }}
            </span>
          </span>
        </div>

        <div class="totalAmount">
          <div style="display: flex; align-items: center;">
            <span style="font-size: 14px; color: #333;">总计</span>&nbsp;
            <div style="font-size: 12px; margin-top: 5px; font-weight: 600;">￥</div>
            <span style="font-size: 24px; color: #333; font-weight: 600; margin-bottom: 3px;">{{ totalPrice.toFixed(2) }}</span>
          </div>

          <van-button
            v-if="shopExist ? false : true"
            icon="plus"
            type="primary"
            size="mini"
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            @click="add_cart_shop"
          >加入购物车</van-button>

          <div v-if="shopExist ? true : false">
            <van-stepper 
              v-model="guigeStepper"
              disable-input
              min="0"
              button-size="19"
              input-width="19"
              @change="stepperChange"
            />
          </div>
        </div>

        <div 
          style="
            width: 39px; height: 39px;
            border: 1px solid #fff;
            background: rgba(147, 147, 147, .4);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            bottom: -65px;
            transform: translateX(-50%);
          "
          @click="dialogShow = false"
        >
          <van-icon 
            size="30" 
            name="cross"
            color="#fff"
          />
        </div>
      </van-dialog>
    </div>
    <share-box v-model="shareBox" :data="data" />
  </div>
</template>

<script>
import { 
  shopDetail, 
  shopCollect, 
  cancelShopCollect, 
  shopevaluate,
  selectStandards,
  newuserflag
} from '@/api/Ge'
import qs from 'qs'
import { mapState, mapMutations } from 'vuex'
import { contact }  from '@/common'
import shareBox from '../shopList/share.vue'
import { nanoid } from 'nanoid'
  export default {
    components: {
      shareBox
    },
    data () {
      return {
        shareBox: false,
        data: {},
        collectShoppingNum: 0, // 收藏
        detail: {},
        evaluateTotal: '', // 好评数
        applauseRate: '', // 好评率
        evaluateContent: [], // 好评内容
        standardName: [],
        show: false,
        dialogShow: false,
        shopExist: false, // 判断商品是否存在
        specificationArr: [], // 规格数组
        priceArr: [], // 价格数组

        standard: [],
        foodId: '', // 菜品Id
        goodsName: '',
        goodsImages: '',
        totalPrice: 0, // 总价
        discountPrice2: null, // 用于计算满减券
        discountPrice: '',
        price: '',

        basicPrice: 0, // 商品列表基础价格
        originalPrice2: 0, // 商品原价
        originalPrice: 0, // 商品原价

        copies: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // 份数
        specificationShow: [], // 已选规格
        guigeStepper: 0, // 规格步进器值

        detailStepper: false,
        detailShowHide: false,
        stockCurrent: '', // 库存
        noSingle: null, // 单点不送
        minBuy: 0, // 起购量

        flag: '', // 0-新用户 1 老用户 
        // flag: 0, // 0-新用户 1 老用户
      }
    },
    computed: {
      ...mapState({
        shopCar: state => JSON.parse(JSON.stringify(state.shopCar.shopCar)),
        transportUserPrice: state => state.shopCar.transportUserPrice,
        im: state => state.shopCar.im,
        transportStartPrice: state => state.shopCar.transportStartPrice,
        disabled: state => state.shopCar.disabled,
      }),
      ids () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/goodsIds']))
      },
      shopPrice () {
        return JSON.parse(JSON.stringify(this.$store.getters['shopCar/shopPrice']))
      },
      transportStartPriceResult () { // 起送价结果
        let transportStartPriceResult = parseFloat(this.transportStartPrice) - parseFloat(this.shopPrice[1])
        return transportStartPriceResult.toFixed(2)
      },
      noDistribution () { // 单点不配送
        return this.$store.getters['shopCar/noDistribution']
      }
    },
    watch: {
      dialogShow (value) {
        if (!value) {
          this.priceArr = []
          this.specificationArr = []
          this.basicPrice = 0
          this.specificationShow = []
        } else {
          this.copies = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      },

      transportStartPriceResult (value) {
        if (value <=0) {
          const noSingleShop = JSON.parse(JSON.stringify(this.shopCar))
          const noSingleShopTrue = noSingleShop.every(item => item.noSingle == '1')
          if (noSingleShopTrue) {
            this['shopCar/set_disabled'](true)
          } else {
            this['shopCar/set_disabled'](false)
          }
        } else {
          this['shopCar/set_disabled'](true)
        }
      }
    },
    created () {
      this.newuserflag()
      this.shopDetail()
      this.shopevaluate()
    },

    mounted () {
      console.log(this.$store.state,'仓库')
    },

    methods: {
      ...mapMutations([
        'shopCar/change_quantity', 
        'shopCar/add_distance', 
        'shopCar/delete_shop',
        'shopCar/set_shopCar',
        'shopCar/transport_User_Price',
        'shopCar/detail_add_shop',
        'shopCar/detail_change_quantity',
        'shopCar/detelt_detail_shop',
        'shopCar/empty_shop_car',
        'shopCar/set_disabled',
      ]),

      contact,

      // 新用户
      newuserflag () {
        newuserflag(qs.stringify({
          shopId: this.$route.query.shopId
        })).then(res => {
          if (res.data.code === 0) {
            this.flag = res.data.data.flag
          }
        })
      },

      // 详情
      shopDetail () {
        shopDetail(qs.stringify({
          shopId: this.$route.query.shopId,
          id: this.$route.query.id
        })).then(res => {
          if (res.data.code === 0) {
            this.detail = res.data.data
            this.standardName = res.data.data.standardName
            this.collectShoppingNum = res.data.data.collection
            this.foodId = res.data.data.goodsId
            this.discountUser = res.data.data.discountUser
            this.goodsName = res.data.data.goodsName
            this.goodsImages = res.data.data.goodsImages[0]
            this.discountPrice = res.data.data.discountPrice
            this.discountPrice2 = res.data.data.discountPrice
            this.stockCurrent = res.data.data.stockCurrent
            this.noSingle = res.data.data.noSingle
            this.minBuy = res.data.data.minBuy
            this.price = res.data.data.price
            this.shopServive(this.foodId)
            this.stepperValue(this.foodId)
            let url = window.location.href;
            this.data = {
              title: 'Win生活外卖',
              picUrl: res.data.data.goodsImages[0],
              content: res.data.data.shopName,
              openUrl:
              url.split("session")[0] +
              "shareUserId=" +
              this.detail.userId +
              "&session" +
              url.split("session")[1],
            }
          }
        })
      },

      // 收藏
      collectShopping (status) {
        this.collectShoppingNum++
        let data = { id: this.$route.query.id, flag: 2 }
        if (status%2 == 0) {
          shopCollect(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              this.$toast('收藏成功')
            }
          })
        } else if (status%2 == 1) {
          cancelShopCollect(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              this.$toast('取消收藏成功')
            }
          })
        }
      },

      shopevaluate () {
        shopevaluate(qs.stringify({
          id: this.$route.query.shopId,
          goodsId: this.$route.query.id
        })).then(res => {
          if (res.data.code === 0) {
            this.evaluateTotal = res.data.data.page.total
            this.evaluateContent = res.data.data.page.records
            this.applauseRate = res.data.data.takeoutContentVO.applauseRate
          }
        })
      },

      // 选规格
      selectSpecification (goodsName, goodsId, 
        goodsImages, basicPrice, 
      originalPrice,discountUser,stockCurrent) {
        if (stockCurrent == 0) {
          this.$toast('商品已售空')
          return
        }
        this.dialogShow = true
        this.dialogShow = true
        this.goodsName = goodsName
        this.goodsId = goodsId
        this.goodsImages = goodsImages
        if (basicPrice) {
          // 所有用户折扣价
          if (discountUser == '0') {
            this.basicPrice = basicPrice
          } else if (discountUser == '1') { // 折扣价只针对新用户
            if (this.flag == '0') {
              this.basicPrice = basicPrice
            } else if (this.flag == '1') {
              this.basicPrice = originalPrice
            }
          }
        } else {
          this.basicPrice = originalPrice
        }
        this.originalPrice2 = originalPrice
        selectStandards(qs.stringify({
          shopId: this.$route.query.shopId,
          goodsId: this.foodId
        })).then(res => {
          if (res.data.code === 0) {
            this.standard = res.data.data
            let price = 0
            res.data.data.forEach((item, index) => {
              item.contenList.forEach((item2, index2) => {
                if (index2 === 0) {
                  this.specificationShow[index] = item2.standardName
                  price = price + item2.addPrice
                  this.priceArr.push(item2.addPrice)
                  let params = {
                    standardId: item2.standardId,
                    id: item2.id
                  }
                  this.specificationArr.push(params)
                }
              })
            })
            this.totalPrice = price + this.basicPrice
            this.originalPrice = price + originalPrice // 规格弹框关闭为侦听
            this.judgeShop(this.foodId, this.specificationArr)
            this.stepperNum(this.foodId, this.specificationArr)
          }
        })
      },

      // 规格
      cppiesTabs (index, index2, name, addPrice, standardId, id) {
        this.copies[index] = index2
        this.copies = this.copies.concat([])
        this.specificationShow[index] = name
        this.priceArr[index] = addPrice 
        let price = 0
        this.priceArr.forEach(item => {
          price = price + item
        })
        this.totalPrice = price + this.basicPrice
        this.originalPrice = price + this.originalPrice2
        let params = {
          standardId,
          id
        }
        this.specificationArr[index] = params
        this.specificationArr = this.specificationArr.concat([])
        this.judgeShop(this.foodId, this.specificationArr)
        this.stepperNum(this.foodId, this.specificationArr)
      },

      judgeShop (goodsId, specificationArr) {
        const specificationArrId = []
        specificationArr.forEach(item2 => {
          specificationArrId.push(item2.id)
        })
        const ids = JSON.parse(JSON.stringify(this.ids))
        const shopCar  = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
        if (ids.includes(goodsId)) {
          const numBer = []
          shopCar.forEach(item => {
            const numArr2 = []
            item.allGoods.forEach(item2 => {
              numArr2.push(item2.id)
            })
            const trueFalse = specificationArrId.every( item => numArr2.includes(item))
            if (trueFalse) {
              numBer.push(1)
            } else {
              numBer.push(2)
            }
          })
          if (numBer.includes(1)) {
            this.shopExist = true
          } else {
            this.shopExist = false
          }
        } else {
          this.shopExist = false
        }
      },

      // 渲染规格弹框步进器
      stepperNum (goodsId, specificationArr) {
        const newSpecificationArr = []
        specificationArr.forEach(item => newSpecificationArr.push(item.id))
        const shopCar = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
        shopCar.forEach(item => {
          const ids = []
          item.allGoods.forEach(item2 => {
            ids.push(item2.id)
          })
          const trueFalse = newSpecificationArr.every(item3 => ids.includes(item3))
          if (trueFalse) {
            this.guigeStepper = item.quantity
          }
        })
      },

      // 步进器
      stepperChange (num) {
        if (num == 0) {
          this['shopCar/delete_shop']({
            num,
            specificationArr: JSON.parse(JSON.stringify(this.specificationArr)),
            goodsId: this.foodId
          })
        } else {
          this['shopCar/change_quantity']({
            goodsId: this.foodId,
            specificationArr: this.specificationArr,
            guigeStepper: this.guigeStepper
          })
          this.stepperNum(this.foodId, this.specificationArr)
        }
        this.judgeShop(this.foodId, this.specificationArr)
      },

      // 面板步进器
      shopCarChange (index, e) {
        if (e === 0) {
          this.shopCar.splice(index, 1)
        }
        this['shopCar/set_shopCar'](this.shopCar)
        
        this.stepperValue(this.foodId)
        this.shopServive(this.foodId)
      },

      // 规格弹框 加入购物车
      add_cart_shop () {
        const Goodsnanoid = nanoid()
        let params = {
          nanoid: Goodsnanoid,
          goodsId: this.foodId,
          goodsName: this.goodsName,
          goodsImages: this.goodsImages,
          price: this.totalPrice,
          originalPrice: this.originalPrice,
          // allGoods: '商品Id@规格Id:规格内容Id_规格Id:规格内容Id@1@商品名称',
          allGoods: this.specificationArr,
          specificationShow: this.specificationShow,
          discountPrice: this.discountPrice2,
          noSingle: this.noSingle,
          minBuy: this.minBuy,
          quantity: 1
        }
        params = JSON.parse(JSON.stringify(params))
        this.$store.commit("shopCar/add_shop", params)
        this.judgeShop(this.foodId, this.specificationArr)
        this.stepperNum(this.foodId, this.specificationArr)
        console.log(this.$store.state.shopCar.shopCar, '计算属性')
      },

      // 购物车
      clickCart () {
        if (this.show == false) {
          this.show = true
        } else {
          this.show = false
        }
      },

      emptyShopCar () {
        this['shopCar/empty_shop_car']()
        this.show = false
      },

      detail_add_cart_shop () {
        let detailShopPrice = 0
        if (this.discountPrice) {
          if (this.discountUser == '0') {
            detailShopPrice = this.discountPrice
          } else if (this.discountUser == '1') {
            if (this.flag == '0') {
              detailShopPrice = this.discountPrice
            } else {
              detailShopPrice = this.price
            }
          }
        } else {
          detailShopPrice = this.price
        }
        const Goodsnanoid = nanoid()
        let params = {
          nanoid: Goodsnanoid,
          goodsId: this.foodId,
          goodsName: this.goodsName,
          goodsImages: this.goodsImages,
          price: detailShopPrice,
          originalPrice: this.price,
          // allGoods: '商品Id@规格Id:规格内容Id_规格Id:规格内容Id@1@商品名称',
          allGoods: this.specificationArr,
          specificationShow: this.specificationShow,
          discountPrice: this.discountPrice2,
          noSingle: this.noSingle,
          quantity: 1
        }
        params = JSON.parse(JSON.stringify(params))
        this['shopCar/detail_add_shop'](params)
        this.shopServive(this.foodId)
        this.stepperValue(this.foodId)
        console.log(this.$store.state.shopCar.shopCar, '加入购物车')
      },

      shopServive (id) {
        const index = this.shopCar.findIndex(item => item.goodsId == id)
        if (index === -1) {
          this.detailShowHide = false
        } else {
          this.detailShowHide = true
        }
      },

      stepperValue (id) {
        const shopCar = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
        const index = shopCar.findIndex(item => item.goodsId == id)
        if (index != -1) {
          this.detailStepper = shopCar[index].quantity
        }
      },

      detailShopChange (num) {
        if (num == 0) {
          this['shopCar/detelt_detail_shop'](this.foodId)
          this.shopServive(this.foodId)
        } else {
          let params = {
            goodsId: this.foodId,
            quantity: num
          }
          params = JSON.parse(JSON.stringify(params))
          this['shopCar/detail_change_quantity'](params)
        }
      },

      // 去结算
      goSettle () {
        const shopCar = JSON.parse(JSON.stringify(this.$store.state.shopCar.shopCar))
        const shopCateId = []
        shopCar.forEach(item => shopCateId.push({ minBuy: item.minBuy, goodsId: item.goodsId, goodsName: item.goodsName }))
        const arr = shopCateId.map(item => JSON.stringify(item))
        const result = Array.from(new Set(arr)).map((item) => JSON.parse(item))
        let shopName = []
        result.forEach(item => {
          const minBuy = item.minBuy
          let quantity = 0
          shopCar.forEach(item2 => {
            if (item.goodsId == item2.goodsId) {
              quantity = item2.quantity + quantity
            }
          })
          if (minBuy > quantity) {
            shopName.push(item.goodsName)
          }
        })
        if (shopName.length) {
          let str = ''
          shopName.forEach((item, index) => {
            if (index != shopName.length-1) {
              str = str + item + ','
            } else {
              str = str + item
            }
          })
          this.$toast(str+ '商品起购数量不足')
        } else {
          this.$router.push({
            path: '/settle',
            query: {
              shopId: this.$route.query.shopId,
              shopName: this.detail.shopName,
            }
          })
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.active {
  border-bottom: 1px solid #FF5200 !important;
  color: #000;
}
.shopping-detail {
  display: flex;
  margin-bottom: 4px;
  
}
.bottom-GoodsAction .van-goods-action-button--first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}
.bottom-GoodsAction  {
  .van-goods-action-icon {
    transform: scale(.83);
  }
  /deep/ .van-goods-action-icon__icon {
    // color: red;
    transform: scale(1.7);
  }
}
.swipe-img {
  img {
    height: 175px;
  }
}
/deep/ .shopCartShop,
/deep/ .detailStepper {
  .van-stepper__plus {
    border-radius: 4px;
    color: white;
    background: #ff5200;
  }
  .van-stepper__minus {
    border-radius: 4px;
    background: white;
    border: 1px solid black;
    outline: none;
  }
  .van-stepper__input {
    background: #fff;
  }
}
.bottom-GoodsAction .van-goods-action-button--first {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}
.bottom-GoodsAction .van-goods-action {
  z-index: 2100;
}
.bottom-GoodsAction  {
  .van-goods-action-icon {
    transform: scale(.83);
  }
  /deep/ .van-goods-action-icon__icon {
    // color: red;
    transform: scale(1.7);
  }
}
/deep/ .shopSpecification {
  position: relative;
  .van-overlay {
    z-index: 2200 !important;
  }
  .van-dialog {
    z-index: 2300 !important;
    .van-dialog__header {
      padding-top: 20px;
    }
  }
  .van-dialog {
    overflow: visible !important;
    .van-button--mini {
      padding: 2px 12.5px;
      
    }
  }
}
.copies {
  border: 1px solid #FFE5C8 !important;
  background: #FFE5C8;
  color: #FF5200;
}
/deep/ .totalAmount {
  padding: 8px 5.3% 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .van-stepper__plus {
    border-radius: 4px;
    color: white;
    background: #ff5200;
  }
  .van-stepper__minus {
    border-radius: 4px;
    background: white;
    border: 1px solid black;
    outline: none;
  }
  .van-stepper__input {
    background: #fff;
  }
}
</style>